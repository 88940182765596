import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './overview.scss';
import { Paragraph, Heading4, Heading2, StrongText, UnorderedList, ListItem, Link, LeadParagraph } from '@entur/typography';
import { ExpandablePanel } from '@entur/expand';
import tavla from './overview-tavla.svg';
import IconCircle from '~/components/IconCircle/IconCircle';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MapPinIcon = makeShortcode("MapPinIcon");
const WifiIcon = makeShortcode("WifiIcon");
const MapIcon = makeShortcode("MapIcon");
const SourceCodeIcon = makeShortcode("SourceCodeIcon");
const RSSIcon = makeShortcode("RSSIcon");
const InternationalIcon = makeShortcode("InternationalIcon");
const PricelistIcon = makeShortcode("PricelistIcon");
const PackageIcon = makeShortcode("PackageIcon");
const SeatIcon = makeShortcode("SeatIcon");
const StandingIcon = makeShortcode("StandingIcon");
const CityIcon = makeShortcode("CityIcon");
const ShoppingCartIcon = makeShortcode("ShoppingCartIcon");
const StatsIcon = makeShortcode("StatsIcon");
const QRIcon = makeShortcode("QRIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview"
    }}>{`Overview`}</h1>
    <LeadParagraph mdxType="LeadParagraph">
    An overview of the APIs Entur provides. We've divided them into the
    categories below by what domain they belong to.
    </LeadParagraph>
    <Heading2 className="overview__subheading" mdxType="Heading2">Open Data and Services</Heading2>
    <Paragraph className="overview__paragraph overview__paragraph--open-data-and-services" mdxType="Paragraph">
    Entur operates the national registry for all public transport in Norway,
    collecting data from 60 public transportation operators. The registry
    contains data about 21,000 daily departures on 3,000 routes. This data is
    open and free of use for app and service developers. Entur believes in open
    source development and we strive to create re-usable components.
    </Paragraph>
    <ExpandablePanel title="Source Code" mdxType="ExpandablePanel">
    <Paragraph margin="none" mdxType="Paragraph">
        Entur believes in open source development and we strive to create
        re-usable components which can be shared and co-developed
        internationally. We also make use of some already well established open
        source projects, such as{' '}
        <Link href="https://www.opentripplanner.org" mdxType="Link">OpenTripPlanner</Link>,
        where we have become active contributors.
    </Paragraph>
    <div className="overview__expandable-grid">
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<MapPinIcon mdxType="MapPinIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">National Stop Registry</Heading4>
            <Paragraph margin="none" mdxType="Paragraph">
                Category: National journey planning. License: EUPL-1.2 The stop
                place registry product consists of two main components, the
                backend Java application Tiamat, and the front end
                JavaScript/React application Abzu. Stops are edited by
                registered users in Abzu's web interface, and the edits are
                saved and versioned in the Tiamat database. In addition to the
                main components, we have a few assistant applications, such as
                asag, which converts Tiamat exports to map data, and Ninkasi
                which handles the user accounts.
            </Paragraph>
            <UnorderedList className="overview__unorderedlist overview__unorderedlist--no-margin" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">
                    <Link href="https://github.com/entur/tiamat" mdxType="Link">
                        https://github.com/entur/tiamat
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    <Link href="https://github.com/entur/abzu" mdxType="Link">
                        https://github.com/entur/abzu
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    Find all the other applications here:{' '}
                    <Link href="https://github.com/entur" mdxType="Link">
                        https://github.com/entur
                    </Link>
                </ListItem>
            </UnorderedList>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<WifiIcon mdxType="WifiIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Timetables</Heading4>
            <Paragraph margin="none" mdxType="Paragraph">
                Category: National journey planning. License: EUPL-1.2 To store
                departures, arrivals, trips and journey patterns, we use the
                application Chouette, developed in France, and selected
                specifically for its native integration towards the NeTEx
                format. Chouette is also responsible for the data import process
                and has extensive data validation functionality.
                <br /> <br />
                We use our own branch of Chouette, both for front- and backend.
            </Paragraph>
            <UnorderedList className="overview__unorderedlist overview__unorderedlist--no-margin" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">
                    Front end:{' '}
                    <Link href="https://github.com/entur/chouette" mdxType="Link">
                        https://github.com/entur/chouette
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    Back end:{' '}
                    <Link href="https://github.com/entur/chouette2" mdxType="Link">
                        https://github.com/entur/chouette2
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    Main branch:{' '}
                    <Link href="https://github.com/afimb/chouette" mdxType="Link">
                        https://github.com/afimb/chouette
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    Main branch:{' '}
                    <Link href="https://github.com/afimb/chouette2" mdxType="Link">
                        https://github.com/afimb/chouette2
                    </Link>
                </ListItem>
            </UnorderedList>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<MapIcon mdxType="MapIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Journey Planner</Heading4>
            <Paragraph margin="none" mdxType="Paragraph">
                Category: National journey planning. License: EUPL-1.2 Entur
                uses- and has dedicated substantial time and resources to the
                continued development of OpenTripPlanner (OTP), and we will
                continue to do so for a foreseeable future. Our interests lie in
                implementing NeTEx integration for OTP, and the improvement of
                search response times, and journey suggestion quality. We are
                contributing to the OTP 2.0 project while using a fork in our
                own products.
            </Paragraph>
            <UnorderedList className="overview__unorderedlist overview__unorderedlist--no-margin" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">
                    Main:{' '}
                    <Link href="https://github.com/opentripplanner/OpenTripPlanner" mdxType="Link">
                        https://github.com/opentripplanner/OpenTripPlanner
                    </Link>
                </ListItem>
                <ListItem mdxType="ListItem">
                    Our fork:{' '}
                    <Link href="https://github.com/Entur/OpenTripPlanner" mdxType="Link">
                        https://github.com/Entur/OpenTripPlanner
                    </Link>
                </ListItem>
            </UnorderedList>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<SourceCodeIcon mdxType="SourceCodeIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">SDK</Heading4>
            <Paragraph mdxType="Paragraph">
                Category: National journey planning. License: EUPL-1.2. Get a
                quick start using Entur's API with some of these SDKs.
            </Paragraph>
            <Link href="https://github.com/entur/sdk" mdxType="Link">
                https://github.com/entur/sdk
            </Link>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<RSSIcon mdxType="RSSIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Tavla</Heading4>
            <Paragraph mdxType="Paragraph">
                Category: National journey planning. License: EUPL-1.2 Our
                homebrewed product "Tavla" ("the board!") is a web service which
                allows users to create their own personalised departure boards.
                It is designed to be used on large screens in public areas, for
                example, the lobby of an office building. The user selects the
                physical location of the board using the geocoder, then defines
                the search radius of the board, and the transport modes to be
                displayed. The application looks up stops within the radius and
                displays them on "the board!". The user can further personalise
                the departure board by filtering out certain routes or
                directions of routes. Each personalised board has a unique URL
                and can be shared freely.
            </Paragraph>
            The product:{' '}
            <Link href="https://tavla.entur.no" mdxType="Link">https://tavla.entur.no</Link>,
            type in a location, then use the cog icon to personalise.
            <br />
            <Link href="https://github.com/entur/tavla" mdxType="Link">
                https://github.com/entur/tavla
            </Link>
        </div>
    </div>
    <div className="overview__tavla">
        <img src={tavla} alt="Tavla" />
    </div>
    </ExpandablePanel>
    <ExpandablePanel title="Journey Planning APIs" mdxType="ExpandablePanel">
    <Paragraph mdxType="Paragraph">
        This category holds the APIs and services that are related to stop
        places, travel search, departures, and so on.
    </Paragraph>
    <Paragraph margin="none" mdxType="Paragraph">
        If you're interested in prices, products and tickets, this is not it.
    </Paragraph>
    <div className="overview__expandable-grid">
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<InternationalIcon mdxType="InternationalIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Geocoder</Heading4>
            <Paragraph margin="none" mdxType="Paragraph">
                Use the Geocoder API to find stop places, addresses and other
                places based on a name query or coordinates. The geocoder is the
                engine behind the autosuggest field on{' '}
                <Link href="https://entur.no" mdxType="Link">entur.no</Link> and the Entur app.
                The results from the geocoder is usually used further in the
                Journey Planner API. <br />
                <br />
                Example questions that the Geocoder API can answer:
            </Paragraph>
            <UnorderedList className="overview__unorderedlist overview__unorderedlist--no-margin" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">
                    What stop places exist with the name "Dronningens gate"?
                </ListItem>
                <ListItem mdxType="ListItem">
                    {' '}
                    What is the address or stop place at coordinates 61.12353, 10.41234?
                </ListItem>
            </UnorderedList>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<MapIcon mdxType="MapIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">National Journey Planner</Heading4>
            <Paragraph mdxType="Paragraph">
                Use the National Journey Planner API to find departures from
                stop places and journeys between places.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<MapPinIcon mdxType="MapPinIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">National Stop Register</Heading4>
            <Paragraph mdxType="Paragraph">
                The National Stop Register (NSR) is primary used to get detailed
                information regarding infrastructure for a stop place. In most
                cases the National Journey Planner API can provide sufficient
                data about stop places. Use the NSR API if you need something
                else.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<MapPinIcon mdxType="MapPinIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Real-Time Data</Heading4>
            <Paragraph mdxType="Paragraph">
                We offer real-time data from all public transport sources in
                Norway on the{' '}
                <Link href="https://en.wikipedia.org/wiki/Service_Interface_for_Real_Time_Information" mdxType="Link">
                    SIRI
                </Link>{' '}
                2.0 format. It comes in the standard SIRI XML or as SIRI Lite
                (ReST).
            </Paragraph>
        </div>
    </div>
    </ExpandablePanel>
    <Heading2 className="overview__subheading" mdxType="Heading2">Partner Services</Heading2>
    <Paragraph className="overview__paragraph overview__paragraph--partner-services" mdxType="Paragraph">
    Entur provides sales and ticketing solutions for rail operators and other
    partners. To access these APIs you need a Partner agreement with Entur. See
    how to{' '}
    <Link href="/pages-intro-setup-and-access" mdxType="Link">
        setup and access these APIs
    </Link>
    </Paragraph>
    <ExpandablePanel title="Offer and Product APIs" mdxType="ExpandablePanel">
    <div className="overview__expandable-grid">
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<PricelistIcon mdxType="PricelistIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Offers</Heading4>
            <Paragraph mdxType="Paragraph">
                API for calculating fares for public transport in Norway.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<PackageIcon mdxType="PackageIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Products</Heading4>
            <Paragraph mdxType="Paragraph">
                API for managing fare-data for public transport.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<PackageIcon mdxType="PackageIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Inventory</Heading4>
            <Paragraph mdxType="Paragraph">
                APIs to deal with quotas. A quota is a limit on the number of
                instances of something that can be reserved at once. A quota
                will always be associated with a particular departure, and
                usually have some sort of geographic constraint
                (origin/destination).
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<SeatIcon mdxType="SeatIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Seating Services</Heading4>
            <Paragraph mdxType="Paragraph">
                APIs for seat reservations and seat allocation. A seat is a
                physical space in some sort of transport (i.e. seat, wheelchair
                seat/space, sleeper, bicycle place etc.).
            </Paragraph>
        </div>
    </div>
    </ExpandablePanel>
    <ExpandablePanel title="Customer and Organisation APIs" mdxType="ExpandablePanel">
    <div className="overview__expandable-grid">
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<StandingIcon mdxType="StandingIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Customers</Heading4>
            <Paragraph mdxType="Paragraph">
                The Customers API can be used to access Entur and Entur partner
                organisation's customers. The features included in the API are:
                Customer profiles, Customer authentication, Temporary profiles,
                Consents and Loyalty programs connected to customers.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<CityIcon mdxType="CityIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Organisations</Heading4>
            <Paragraph mdxType="Paragraph">
                Organisation register ("organisations") is service for storing
                and retrieving organisations referred to by Entur services.
            </Paragraph>
        </div>
    </div>
    </ExpandablePanel>
    <ExpandablePanel title="Sales APIs" mdxType="ExpandablePanel">
    <Paragraph margin="none" mdxType="Paragraph">
        These APIs provide functionality for closing the deal: selling products,
        processing payments and distributing tickets.
    </Paragraph>
    <div className="overview__expandable-grid">
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<ShoppingCartIcon mdxType="ShoppingCartIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Sales</Heading4>
            <Paragraph mdxType="Paragraph">
                A collection of all the APIs needed to perform a sale ranging
                from the creation of Orders and receiving Payments to sending
                Receipts to the customer.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<StatsIcon mdxType="StatsIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Point-of-Sale Registry</Heading4>
            <Paragraph mdxType="Paragraph">
                The point of sale register keeps track of all points of sale
                which use our cash register system.
            </Paragraph>
        </div>
        <div className="overview__expandable-grid__section-icon">
            <IconCircle icon={<QRIcon mdxType="QRIcon" />} mdxType="IconCircle" />
        </div>
        <div>
            <Heading4 margin="none" mdxType="Heading4">Distribution Channel</Heading4>
            <Paragraph mdxType="Paragraph">
                Distribution Channels controls which organisations may sell,
                refund or inform on available products or sale points.
            </Paragraph>
        </div>
    </div>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      